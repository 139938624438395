@font-face {
    font-family: 'Work Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans'),
        url('../fonts/WorkSans-Regular.woff2') format('woff2'),
        url('../fonts/WorkSans-Regular.eot') format('embedded-opentype'),
        url('../fonts/WorkSans-Regular.woff') format('woff');
    font-display: fallback;
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Work Sans'),
        url('../fonts/WorkSans-Medium.eot') format('embedded-opentype'),
        url('../fonts/WorkSans-Medium.woff2') format('woff2'),
        url('../fonts/WorkSans-Medium.woff') format('woff');
    font-display: fallback;
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    src: local('Work Sans'),
        url('../fonts/WorkSans-Bold.eot') format('embedded-opentype'),
        url('../fonts/WorkSans-Bold.woff2') format('woff2'),
        url('../fonts/WorkSans-Bold.woff') format('woff');
    font-display: fallback;
}

@font-face {
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: normal;
    src: local('Work Sans'),
        url('../fonts/WorkSans-Italic.eot') format('embedded-opentype'),
        url('../fonts/WorkSans-Italic.woff2') format('woff2'),
        url('../fonts/WorkSans-Italic.woff') format('woff');
    font-display: fallback;
}


@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: normal;
    src: local('Roboto'),
        url('../fonts/Roboto-Italic.eot') format('embedded-opentype'),
        url('../fonts/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto-Italic.woff') format('woff');
    font-display: fallback;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
        url('../fonts/Roboto-Bold.eot') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto'),
        url('../fonts/Roboto-Medium.eot') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff');
    font-display: fallback;
}

@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    src: local('Roboto'),
        url('../fonts/Roboto-Regular.eot') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff');
    font-display: fallback;
}