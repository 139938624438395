@import './mixin.scss';

.fondo_card {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../public/img/Imgcard.png');
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .list_product {
        list-style: none;
        padding-left: 0;

        li {
            font-size: 1vw;
            text-align: center;
            text-transform: lowercase;
        }
    }

    .link_card {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 1920px) {
    .fondo_card {
        .list_product {
            li {
                font-size: 1rem;
            }
        }
    }
}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .fondo_card {
        .list_product {
            li {
                font-size: 0.9rem;
            }
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .fondo_card {
        .list_product {
            li {
                font-size: 0.9rem;
            }
        }
    }
}

//only phone
@media (max-width: 767px) {
    .fondo_card {
        .list_product {
            li {
                font-size: 0.9rem;
            }
        }
    }
}

//only phone
@media (max-width: 576px) {}