@import './mixin.scss';

.btnPaginate {
    position: relative;
    height: 2.396vw;
    width: 2.396vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}

.btn_previous {
    //background-color: var(--color-soccess);
    margin-right: 1vw;
    @extend .btnPaginate;
   // border: 1px solid var(--color-soccess);
   font-size: 2rem;
   color: #000000
}

.btn_inactivo {
    @extend .btnPaginate;
    margin-right: 1vw;
    background-color: var(--color-gris);
    border: 1px solid var(--color-gris);
}

.btn_next {
   // background-color: var(--color-soccess);
    @extend .btnPaginate;
    //border: 1px solid var(--color-soccess);
    font-size: 2rem;
    color: #000000
}

.btn_inactivonext {
    @extend .btnPaginate;
    background-color: var(--color-gris);
    border: 1px solid var(--color-gris);
}

.btn_number {
    position: relative;
    height: 2.396vw;
    width: 2.396vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gris);
    margin-right: 1vw;
    color: var(--color-gris);
    font-weight: bold;
}

.active {
    position: relative;
    height: 2.396vw;
    width: 2.396vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-soccess);
    background-color: var(--color-soccess);
    color: #000;
    font-weight: 700;
}

.break {
    position: relative;
    height: 2.396vw;
    width: 2.396vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gris);
    margin-right: 1vw;
    color: var(--color-gris);
}

.container_paginate {
    display: flex;
    align-items: center;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .btn_previous {
        height: 1.9rem;
        width: 1.9rem;

    }

    .btn_next {
        height: 1.9rem;
        width: 1.9rem;
    }

    .btn_number {
        height: 1.9rem;
        width: 1.9rem;

    }

    .active {
        height: 1.9rem;
        width: 1.9rem;
    }

    .break {
        height: 1.9rem;
        width: 1.9rem;
    }

    .btn_inactivo {
        height: 1.9rem;
        width: 1.9rem;
    }

    .btn_inactivonext {
        height: 1.9rem;
        width: 1.9rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}

//only MD
@media (max-width: 767px) {
    .btn_previous {
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.3rem;
    }

    .btn_next {
        height: 1.6rem;
        width: 1.6rem;
    }

    .btn_number {
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.3rem;
    }

    .active {
        height: 1.6rem;
        width: 1.6rem;
    }

    .break {
        height: 1.6rem;
        width: 1.6rem;
    }

    .btn_inactivo {
        height: 1.6rem;
        width: 1.6rem;
    }

    .btn_inactivonext {
        height: 1.6rem;
        width: 1.6rem;
    }

}

//only phone
@media (max-width: 576px) {
    .btn_previous {
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.3rem;
    }

    .btn_next {
        height: 1.6rem;
        width: 1.6rem;
    }

    .btn_number {
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.3rem;
    }

    .active {
        height: 1.6rem;
        width: 1.6rem;
    }

    .break {
        height: 1.6rem;
        width: 1.6rem;
    }

    .btn_inactivo {
        height: 1.6rem;
        width: 1.6rem;
    }

    .btn_inactivonext {
        height: 1.6rem;
        width: 1.6rem;
    }

}