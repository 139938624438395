@import './var.scss';
@import './mixin.scss';

.fondo_img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 100vh;

	&.login_fondo {
		background-image: url('../../public/img/fondologin.png');
	}
}

.elementos:last-child {
	border-bottom: 1px solid #000; /* El último elemento siempre tendrá un borde inferior */
 }

.elementos {
	border: 1px solid #000; /* Aplica bordes uniformes a cada elemento */
	border-top: none; /* Evita un borde superior duplicado entre elementos */
	padding: 0.5vw 1vw; /* Asegura un relleno adecuado */
 }
 
 .elementos:first-child {
	border-top: 1px solid #000; /* Solo el primer elemento tiene un borde superior */
 }

 .card_product {
	width: 100%; /* Usa el 100% del espacio disponible */
	max-width: 60%; /* Limita el ancho máximo para mantener la proporción */
	margin: 0 auto; /* Centra el contenido */
	display: flex;
	flex-direction: column; /* Asegura que los campos estén en una columna */
 }
 

 

/** lista de  PreCompra **/

.listacompra {
	list-style: none;
	max-height: 70vh;
	overflow: auto;

	li {
		font-size: 1.5vw;
		color: var(--color-madera);
		font-weight: 400;
	}
}

/** Cuadro resultado de inventario*/
.cuadro_inventario {
	border: 1px solid black;
}

.rowpeciales {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 1vw;

	.col {
		width: 30%;
	}
}

/** etilo para el formulario de agregar cliente **/
.containerFormCotiza {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 2vw;

	.hija_2 {
		width: 30%;
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: end;
	}
}

/** Genera un scroll vertical a los elementos**/

.scrollElement {
	max-height: 50vh;
	height: 100%;
	overflow-y: auto;
}

.contenedor_acordeon {
	margin: 0 auto;
	width: 60%;
}

.form_w {
	width: 50%;
}

@media (min-width: 1920px) {
	.listacompra {
		li {
			font-size: 1.3rem;
		}
	}
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.form_w {
		width: 80%;
	}

	.contenedor_acordeon {
		width: 100%;
	}

	.containerFormCotiza {
		flex-direction: column;
		gap: 2rem;

		.hija_2 {
			width: 100%;
		}
	}

	.containerFormCotiza {
		.hija_2 {
			width: 100%;
		}
	}

	.rowpeciales {
		gap: 1rem;

		.col {
			width: 100%;
		}
	}

	.listacompra {
		li {
			font-size: 1.5rem;
		}
	}

	.card_product {
		width: 100%;
		max-width: 100%;
		.elementos {
			padding: 0.5rem 1rem;
		}
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.contenedor_acordeon {
		width: 80%;
	}

	.rowpeciales {
		gap: 1rem;

		.col {
			width: 45%;
		}
	}

	.listacompra {
		li {
			font-size: 1.5rem;
		}
	}

	.card_product {
		width: 70%;
       max-width: 70%;
		.elementos {
			padding: 0.5rem 1rem;
		}
	}
}

//only phone
@media (max-width: 767px) {
	.form_w {
		width: 100%;
	}

	.contenedor_acordeon {
		width: 100%;
	}

	.containerFormCotiza {
		flex-direction: column;
		gap: 2rem;

		.hija_2 {
			width: 100%;
		}
	}

	.rowpeciales {
		gap: 1rem;

		.col {
			width: 100%;
		}
	}

	.listacompra {
		li {
			font-size: 1.5rem;
		}
	}

	.card_product {
		width: 100%;
		max-width: 100%;
		.elementos {
			padding: 0.5rem 1rem;
		}
	}
}

//only phone
@media (max-width: 576px) {

	.wselect {
		width: 100%;
	}

	.listacompra {
		li {
			font-size: 1rem;
		}
	}
}