@import './var.scss';
@import './mixin.scss';
@import './fonts.scss';

.input-small {
	width: 7vw;
}

.btn-disabled {
	background-color: grey;
	/* Cambia el color de fondo a gris */
	color: white;
	/* Cambia el color del texto si es necesario */
	pointer-events: none;
	/* Deshabilita la interacción */
	opacity: 0.6;
	/* Reduce la opacidad para un efecto visual de deshabilitado */
}

.WorkSans {
	font-family: 'Work Sans Regular' !important;
}

.WorkSans-medium {
	font-family: 'Work Sans';
	font-weight: 500;
}

.roboto {
	font-family: 'Roboto' !important;
}

.bg-danger {
	background-color: var(--color-danger);
}

.color-danger {
	color: var(--color-danger);
}

.bg-soccess {
	background-color: var(--color-soccess) !important;
}

.color-soccess {
	color: var(--color-soccess);
}

.bg-madera {
	background-color: var(--color-madera);
}

.color-madera {
	color: var(--color-madera) !important;
}

.bg-whiteSegundary {
	background-color: var(--color-white-segundary);
}

.color-whiteSegundary {
	color: var(--color-white-segundary);
}

.bg-azul {
	background-color: var(--color-azul) !important;
}

.color-azul {
	color: var(--color-azul);
}

.bg-dark {
	background-color: #000;
}

.color-dark {
	color: #000 !important;
}

.color-gris {
	color: var(--color-gris);
}

.bg-gris {
	background-color: var(--color-gris);
}

.color-white {
	color: white !important;
}

.bg-white {
	background-color: white;
}

.bg-yellow {
	background-color: rgb(221, 218, 29) !important;
}

.bg-red {
	background-color: rgb(201, 0, 0) !important;
}

.color-plata {
	color: var(--color-plata);
}

.bg-plata {
	background-color: var(--color-plata);
}

.bg-gris-modal {
	background-color: var(--color-gris-modal);
}

.color-gris-modal {
	color: var(--color-gris-modal);
}

.box-text {
	text-shadow: 0px 3px 6px #f5f5f5;
}

.fw400 {
	font-weight: 400;
}

.fw500 {
	font-weight: 500;
}

.fw600 {
	font-weight: 600;
}

.fw700 {
	font-weight: 700;
}

.content-app {
	display: flex;
	align-items: flex-start;

	.menu {
		width: 15%;
		position: relative;

		.barra-lateral {
			position: fixed;
			top: 0;
			left: 0;
			//height: 100vh;
			width: 15%;
		}
	}

	.content {
		width: 85%;
	}
}

.conatiner-global {
	padding-left: 4vw;
	padding-right: 4vw;
}

.header-movil {
	display: none;
}

/** Modal General **/
.modal-general {
	.modal-footer {
		border-top: 1px solid transparent !important;
	}

	.modal-content {
		min-width: 30vw;
		border-radius: 20px;
		max-height: 100vh;
		height: 100%;
		overflow: auto;
	}

	.modal-body {
		border-radius: 20px;
	}
}

/** estilos para las tablas  **/

.tableGeneral {
	th {
		place-items: center;
		/* Centra tanto horizontal como verticalmente */
	}

	td {
		font-size: 1vw;
		font-weight: 700;
	}

	//Para colocar el encabezado de las tablas en mayusculas
	th {
		text-transform: uppercase;
		text-align: center;
	}
}

.containerModal {
	display: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1050;
	background: linear-gradient(rgba(44, 42, 41, 0.8), #2c2a29);
}

/** estilos del buscardor */

.container_search {
	display: flex;
	align-items: center;
	border: 1px solid var(--border-search);
	border-radius: 0.375rem;
	padding: 0.375rem 0.75rem;

	input[type='search'] {
		border: 0 !important;
		outline: none !important;
		outline-color: none !important;
	}

	.icon-search {
		font-size: 1.5vw;
	}
}

// clase general para clotar el boton
.flotar {
	position: fixed;
	bottom: 2vw;
	right: 2vw;
	z-index: 99;
}

.hover-pointer {
	cursor: pointer;
}

.modal-table {
	.modal-content {
		width: 50vw;
	}
}

.fixclase {
	margin-top: auto;
}

.border-botton {
	border-bottom: 1px solid var(--color-gris);
}

.modal-notificacion {
	.modal-content {
		max-width: 1300px;
		width: 94vw;
	}

	.modal-dialog {
		max-height: 100%;
		max-width: 100% !important;
	}

}

@media (min-width: 1920px) {
	.container_search {
		.icon-search {
			font-size: 1.3rem;
		}
	}

	.modal-general {
		.modal-content {
			min-width: 30rem;
		}
	}

	.content-app {
		.menu {
			width: 15%;

			.barra-lateral {
				width: 15%;
			}
		}

		.content {
			width: 85%;
		}
	}
}

.link {
	text-decoration: none;
}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.modal-table {
		.modal-content {
			width: auto;
		}
	}

	.container_search {
		.icon-search {
			font-size: 1.2rem;
		}
	}

	.flotar {
		bottom: 2rem;
		right: 2rem;
	}

	.tableGeneral {

		th,
		td {
			font-size: 1.2rem;
		}
	}

	.table {
		min-width: 70rem;
	}

	.modal-general {
		.modal-content {
			min-width: 30rem;
		}
	}

	.conatiner-global {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.content-app {
		.menu {
			width: 30%;

			.barra-lateral {
				width: 30%;
			}
		}

		.content {
			width: 70%;
		}
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.flotar {
		bottom: 2rem;
		right: 2rem;
	}

	.tableGeneral {


		td {
			font-size: 1.2rem;
		}
	}

	.table {
		min-width: 70rem;
	}

	.modal-general {
		.modal-content {
			min-width: 30rem;
		}
	}

	.conatiner-global {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.content-app {
		.menu {
			width: 20%;

			.barra-lateral {
				width: 20%;
			}
		}

		.content {
			width: 80%;
		}
	}
}

@media (max-width: 767px) {
	.container_search {
		.icon-search {
			font-size: 1.2rem;
		}
	}

	.flotar {
		bottom: 2rem;
		right: 2rem;
	}

	.tableGeneral {

		th,
		td {
			font-size: 1.2rem;
		}
	}

	.table {
		min-width: 70rem;
	}

	.modal-general {
		.modal-content {
			//width: 100%;
			min-width: 100%;
		}

		.modal-dialog {
			width: 100%;
		}
	}

	.conatiner-global {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.content-app {
		.menu {
			width: 20%;

			.barra-lateral {
				width: 20%;
			}
		}

		.content {
			width: 80%;
		}
	}
}

//only phone
@media (max-width: 576px) {
	.fixclase {
		margin-bottom: 5rem;
	}

	.flotar {
		bottom: 0.2rem;
		right: 2rem;
	}

	.containerModal {
		display: block;
	}

	.table {
		min-width: 70rem;
	}

	.header-movil {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.imgLogo {
			height: 4rem;
			position: relative;
			margin-right: 1rem;
		}
	}

	.content-app {
		position: relative;
		flex-direction: column;

		.menu {
			width: 0%;
			position: fixed;
			left: 0;
			transform: translate(-10rem);
			top: 0;

			&.active {
				left: 0;
				transform: translate(0rem);
				width: 65%;
				z-index: 9991;
			}

			@include toAnim(0.3s);

			.barra-lateral {
				width: 100%;
			}
		}

		.content {
			width: 100%;
		}
	}
}