@import './mixin.scss';

.loader {
    border: 16px solid var(--color-grisclaro);
    border-top: 16px solid var(--color-madera);
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    animation: spin 1.5s linear infinite;
}

/* Definición de la animación */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Estilos para centrar el loader en la pantalla */
.loading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

@media (min-width: 1920px) {
    .loader {
        width: 10rem;
        height: 10rem;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .loader {
        width: 10rem;
        height: 10rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .loader {
        width: 10rem;
        height: 10rem;
    }
}

//only phone
@media (max-width: 767px) {
    .loader {
        width: 10rem;
        height: 10rem;
    }
}