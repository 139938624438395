.h1 {
    font-size: 4vw;
}

.h2 {
    font-size: 2.2vw;
}

.h4 {
    font-size: 1.5vw;
}

.p {
    font-size: 1vw;
}

@media (min-width: 1920px) {
    .h1 {
        font-size: 4rem;
    }

    .h2 {
        font-size: 2.2rem;
    }

    .h4 {
        font-size: 1.5rem;
    }

    .p {
        font-size: 1rem;
    }
}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .h1 {
        font-size: 4rem;
    }

    .h2 {
        font-size: 2rem;
    }

    .h4 {
        font-size: 1.2rem;
    }

    .p {
        font-size: 1rem;
    }

}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

    .h1 {
        font-size: 3.5rem;
    }

    .h2 {
        font-size: 2rem;
    }

    .h4 {
        font-size: 1.5rem;
    }

    .p {
        font-size: 1rem;
    }
}

//only phone
@media (max-width: 767px) {

    .h1 {
        font-size: 3.2rem;
    }

    .h2 {
        font-size: 2rem;
    }

    .h4 {
        font-size: 1.2rem;
    }

    .p {
        font-size: 0.8rem;
    }
}

//only phone
@media (max-width: 576px) {
    .h1 {
        font-size: 2.5rem;
    }

    .h2 {
        font-size: 1.2rem;
    }

    .h4 {
        font-size: 0.9rem;
    }
}