@import './var.scss';
@import './mixin.scss';

.loginContainer {
    background-color: var(--color-white-segundary);
    border: 1px solid var(--color-gris);
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    padding: 2vw 3.5vw;
}

.containar_Input {
    border: 1px solid var(--color-gris);
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 0.2vw 0.8vw;

    input {
        outline: none;
        border: 0;
    }
}


label {
    font-size: 1vw;
    color: var(--color-grisclaro);
}

.input_fuera {
    label {
        color: var(--color-madera);
        margin-bottom: 0.2vw;
    }
}


/** estile Checkbox**/

.checkbox {

    input[type="checkbox"] {
        cursor: pointer;
        width: 1.2vw;

    }

}

@media (min-width: 1920px) {
    .loginContainer {
        padding: 2rem 3.5rem;
    }

    .containar_Input {
        padding: 0.2rem 0.8rem;
    }

    label {
        font-size: 1rem;
    }

    .input_fuera {
        label {
            margin-bottom: 0.2rem;
        }
    }
}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .checkbox {

        input[type="checkbox"] {
            width: 1.2rem;
        }

    }

    .loginContainer {
        padding: 2rem 3.5rem;
    }

    .containar_Input {
        padding: 0.2rem 0.8rem;
    }

    label {
        font-size: 1rem;
    }

    .input_fuera {
        label {
            margin-bottom: 0.2rem;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

    .checkbox {

        input[type="checkbox"] {
            width: 1.2rem;
        }

    }

    .loginContainer {
        padding: 2rem 3.5rem;
    }

    .containar_Input {
        padding: 0.2rem 0.8rem;
    }

    label {
        font-size: 1rem;
    }

    .input_fuera {
        label {
            margin-bottom: 0.2rem;
        }
    }
}

//only phone
@media (max-width: 767px) {

    .checkbox {
        input[type="checkbox"] {
            width: 1.2rem;
        }
    }

    .loginContainer {
        padding: 2rem 3.5rem;
    }

    .containar_Input {
        padding: 0.2rem 0.8rem;
    }

    label {
        font-size: 1rem;
    }

    .input_fuera {
        label {
            margin-bottom: 0.2rem;
        }
    }
}

//only phone
@media (max-width: 576px) {}