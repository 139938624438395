@import './mixin.scss';

.btn {
    box-shadow: 0px 3px 6px var(--box-socess);
    border: 1px solid var(--color-gris);
    border-radius: 15px !important;
    padding: 0.2vw 1vw;
    @include toAnim(0.3s);
    text-decoration: none;

    &.primary {
        background-color: var(--color-soccess);
        color: white;

    }

    &.secundary {
        background-color: var(--color-azul);
        color: white;

    }

    &.danger {
        background-color: var(--color-danger);
        color: white;
    }

    &:hover {
        background-color: white;
        color: #000;
        @include toAnim(0.3s);
    }
}

.btnLink {
    font-size: 3vw;
    display: inline-flex;
}

.btnLink_img {
    height: 3vw;
}

.btn_desnudo {
    background-color: transparent;
    border: 0;
    padding: 0;

    &.text {
        font-size: 1.3vw;
    }

    &.icon {
        font-size: 3vw;
    }

    &.line{
        font-size: 1.2vw;
        border-bottom: 1px solid black;
        border-radius: 0;
    }
}

@media (min-width: 1920px) {
    .btn {
        padding: 0.2rem 1rem;
    }
}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .btn {
        padding: 0.2rem 1rem;
    }

    .btnLink {
        font-size: 3rem;
    }

    .btnLink_img {
        height: 3rem;
    }

    .btn_desnudo {
        &.text {
            font-size: 1.3rem;
        }
    
        &.icon {
            font-size: 3rem;
        }
        &.line{
            font-size: 1.2rem;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .btn {
        padding: 0.2rem 1rem;
    }

    .btnLink {
        font-size: 2.6rem;
    }

    .btnLink_img {
        height: 2.5rem;
    }

    .btn_desnudo {
        &.text {
            font-size: 1rem;
        }
    
        &.icon {
            font-size: 3rem;
        }

        &.line{
            font-size: 1.2rem;
        }
    }
}

//only phone
@media (max-width: 767px) {
    .btn {
        padding: 0.2rem 1rem;
    }

    .btn_desnudo {
        &.line{
            font-size: 1rem;
        }

        &.icon {
            font-size: 3rem;
        }
    }
}

//only phone
@media (max-width: 576px) {
    .btn_desnudo {
        &.text {
            font-size: 1.2rem;
        }
    
        &.icon {
            font-size: 3rem;
        }
        &.line{
            font-size: 1rem;
        }
    }

    .btnLink {
        font-size: 2.6rem;
    }

    .btnLink_img {
        height: 2.5rem;
    }
}