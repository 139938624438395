@import './mixin.scss';

.container_alert {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0vw 2vw 2vw 2vw;
    position: fixed;
    transform: rotate(20rem);
    left: auto;
    z-index: 999;
    transform: translateY(-10rem);
    top: 0;
    @include toAnim(0.3s);
}

.container_alert.active {
    transform: translateY(0rem);
    @include toAnim(0.3s);
}

@media (min-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .container_alert {
        padding: 0rem 2rem 2rem 2rem;
        left: 15rem;

    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .container_alert {
        padding: 0rem 2rem 2rem 2rem;
        left: 25rem;

    }
}

//only phone
@media (max-width: 767px) {
    .container_alert {
        padding: 0rem 2rem 2rem 2rem;
        left: 40%;

    }
}

//only phone
@media (max-width: 576px) {
    .container_alert {
        padding: 0rem 2rem 2rem 2rem;
        width: 90%;
        left: 1rem;
    }
}