.viewpdf {
    position: fixed;
    top: 0;
    left: 7px;
    width: 99%;
    height: 100%;
    background-color: #3e3e3e;
}

.nuevapagina {
    page-break-before: always;
}

.container_pdf {
    background: #C7C6C1;
    min-height: 11.68in;
    height: 11.68in;
    padding: 20px 30px;
}

.caja_fecha {
    border: 1px solid #000;
    padding: 10px 20px;
    border-radius: 6px;

}

.card_cliente {
    border: 1px solid var(--color-gris);
    border-radius: 10px;
    margin-top: 20px;
    background-color: white;

    .card_documento {
        border-bottom: 1px solid var(--color-gris);
        display: flex;
        justify-content: flex-start;

        p:nth-child(1) {
            margin-left: 20px;
        }


    }

    .linea {
        margin-left: 20px;
        margin-right: 20px;
        width: 1px;
        background-color: var(--color-gris);
    }

    .card_direccion {
        border-bottom: 1px solid var(--color-gris);

        p {
            margin-left: 20px;
        }
    }

    .card_celular {
        p {
            margin-left: 20px;
        }
    }
}

.styled_table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;

    thead {
        tr {
            background-color: #fff;
            color: #000;
            text-align: left;
            font-weight: bold;
        }
    }
}

tbody {
    tr {
        background-color: #fff;
        color: #000;
    }
}


.styled_table th,
.styled_table td {
    padding: 7px 10px;
    border: 1px solid #000;
    text-align: center;
 
}

.styled_table tbody td.empty_row {
    border: none;
    border-left: 1px solid var(--color-gris);
    border-right: 1px solid var(--color-gris);
    line-height: 1.3;
}

.styled_table tfoot {
    border-bottom: 1px solid var(--color-gris);
    position: relative;
}

.styled_table tfoot td {
    border: none;
    font-weight: bold;
    border-left: 1px solid var(--color-gris);
    border-right: 1px solid var(--color-gris);
}

.styled_table tfoot tr {
    background-color: #fff;
}

.styled_table tfoot td:last-child {
    text-align: right;
    width: 150px;
    /* Adjust width for proper alignment */
}

.styled_table tfoot td:nth-child(5) {
    text-align: center;
    padding-right: 10px;
    border-bottom: 1px solid var(--color-gris);
    border-top: 1px solid var(--color-gris);
}

.styled_table tfoot td:nth-child(6) {
    text-align: center;
    padding-right: 10px;
    border-bottom: 1px solid var(--color-gris);
    border-top: 1px solid var(--color-gris);
}

.cello {
    border: 3px solid red;
    border-radius: 10px;
    transform: rotateZ(-35deg);
    color: red;
    font-size: 31px;
    position: absolute;
    padding: 5px 10px;
    left: 200px;
    top: 40px;
}

.btn_volver{
    position: fixed;
    bottom: 9px;
    right: 15px;
}