@import './mixin.scss';

.navbar {
    height: 100%;
    max-height: 100%;
    overflow: auto;

    ul {
        list-style: none;

        li {
            color: var(--color-white);
            font-size: 1.8vw;

            a {
                color: white;
                text-decoration: none;
            }

            &.active {
                border-bottom: 2px solid white;
            }
        }
    }
}

.containerIconcar {
    position: relative;
    
    span{
        position: absolute;
        right: 0;
        top: 0;
        color: white;
        background: var( --color-soccess);
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 0.8rem;
        padding: 0.6rem;
    }
}

@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .navbar {

        ul {

            li {
                font-size: 1.7rem;
            }
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .navbar {

        ul {

            li {
                font-size: 1.7rem;
            }
        }
    }
}

//only phone
@media (max-width: 767px) {}

//only phone
@media (max-width: 576px) {
    .navbar {

        ul {

            li {
                font-size: 1.7rem;
            }
        }
    }
}