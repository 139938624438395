:root {
  /* Colores */
  --background-main: rgba(36, 38, 55, 0.60);
  --color-danger: #F90404;
  --color-soccess: #337738;
  --color-madera: #5E3A10;
  --color-white-segundary: #FBF9F9;
  --color-white: #FFFFFF;
  --color-azul: #230BCE;
  --color-gris: #707070;
  --color-grisclaro: #989696;
  --color-gris-modal:#B4B1B1;
  --box-socess: #26D51929;
  --color-plata: #ede6e6;
  --border-search:#dee2e6;

}