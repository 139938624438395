@import './mixin.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    border-bottom: 2px solid var(--color-gris);

    .header_logo {
        display: flex;
        align-items: end;

    }
}

@media (min-width: 1920px) {

    .header {
        .header_logo {
            .logo {
                height: 12rem;
            }

        }
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .header {
        .header_logo {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

//tables sm
@media (max-width: 767px) {
    .header {
        .header_logo {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

//only phone
@media (max-width: 576px) {
    .header {
        .header_logo {
            display: none;
        }
    }
}